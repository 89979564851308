import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

import Image from "../Image";
import "./ShtoriDenINosht.scss";

const ShtoriDenINosht = ({ frontmatter }) => {
  return (
    <div className="container d-flex flex-wrap pt-5 pt-lg-6">
      <h3 className="col-12 pb-2">{frontmatter.header}</h3>
      <div className="d-flex justify-content-lg-start justify-content-center flex-column col-12 px-md-0 px-2 col-lg-6 px-0">
        <Carousel
          variant="dark"
          className="col-md-8 col-lg-12 align-self-center flex-md-shrink-1"
          indicators={false}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="#212529" />}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="#212529" />}
        >
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="rolo_shtori/1.jpg"
              alt="Роло щора"
              style={{ height: "300px" }}
            />
            <Carousel.Caption>Роло щора</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image
              className="w-auto"
              fileName="rolo_shtori/2.jpg"
              alt="Роло щора"
              style={{ height: "300px" }}
            />
            <Carousel.Caption>Роло щора</Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="row justify-content-center my-3">
          <Link className="btn btn-primary" to="/gallery/#rolo_shtori" activeClassName="active">
            Към Галерията
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="mb-2">
          <strong>Стилни, изискани, свежи...</strong>
        </div>
        <div>
          Роло щорите - познати, позабравени и възродени, са продуктът за вътрешно засенчване,
          претърпял най-динамично развитие в последните години. Предлагаме серията модели Ден и Нощ,
          които съчетава предимствата на класическите роло щори и тези на ламелните щори, .
        </div>
      </div>
      {/* <div className="col-12 mt-4 mt-lg-0"> */}
      {/* <div className="mb-2">
          <strong>Елегантни...</strong>
        </div>
        <div>
          С нашите роло щори Вие може да блокирате достъпа на светлина и топлина, да си осигурите
          видимост към външния свят, но да се скриете от любопитни погледи, да филтрирате слънчевите
          отблясъци, без да спирате притока на дневна светлина в помещения. Роло щорите &quot;Ден и
          Нощ&quot; се изработват от платове с редуващи се последователно прозрачни и непрозрачни
          ивици. Механизмите са лесни за употреба. Спускането и повдигането на щорите се осъществява
          посредством верижка.
        </div>
      </div> */}
    </div>
  );
};

ShtoriDenINosht.propTypes = {
  frontmatter: PropTypes.object,
};

ShtoriDenINosht.defaultProps = {
  frontmatter: {},
};

export default ShtoriDenINosht;
